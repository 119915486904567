<template>
  <div class="violations-register__header">
    <div class="violations-register__header-block">
      <r-title>Реестр нарушений</r-title>
      <r-button
        type="primary"
        @click="$emit('add')"
      >
        Добавить нарушение
      </r-button>
    </div>
    <div class="violations-register__header-block">
      <export-button
        name="Реестр нарушений"
        :uri="exportUrl"
      />
    </div>
  </div>
</template>

<script>
import exportButton from '@/components/export-button'
import { exportConfig } from '../../configs'
import { makeExportUrl } from '@/utils'

export default {
  components: { exportButton },
  props: {
    uri: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      exportUrl: ''
    }
  },
  watch: {
    uri() {
      this.initUrl()
    }
  },
  mounted() {
    this.initUrl()
  },
  methods: {
    initUrl() {
      this.exportUrl = makeExportUrl(this.uri, exportConfig)
    }
  }
}
</script>

<style lang="scss">
.violations-register__header {
  flex: 1;
  display: grid;
  justify-items: start;
  grid-gap: 2rem;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--bg_panel_primary);
  border-bottom: 1px solid var(--dividers_low_contrast);

  &-block {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2rem;
    align-items: center;
  }
}
</style>
